app.listenToElement('.form-label', $label => {
  const labelFor = $label.attr('for');
  const $input = labelFor ? $label.parent().find(`#${labelFor}`) : $label.next(':input');

  if ($input.length > 0) {
    const labelFloat = toggleFloat($input);

    $label.toggleClass('focused', labelFloat);
  }
});

app.listenEvents(
  'change/focusin/focusout/select2:open/select2:close   .form-label ~ :input',
  ($input, event) => {
    const $label = $input.parent().find(`label[for=${$input.attr('id')}]`);
    const labelFloat = event.type === 'focusin' || event.type === 'select2:open' || toggleFloat($input);

    $label.toggleClass('focused', labelFloat);
  },
);

function toggleFloat($input) {
  return isFocused($input)
    || !!$input.attr('placeholder')
    || !!$input.attr('data-placeholder')
    || !inputIsEmpty($input);
}

function inputIsEmpty($input) {
  const isSelect = $input[0].tagName.toLowerCase() === 'select';
  const value = $input.val();

  // check selected option in case of first empty option with label
  return isSelect ? !$input.find('option:selected')?.[0]?.label?.trim() : value === null || value.length === 0;
}

function isFocused($input) {
  return $input[0] === document.activeElement;
}
